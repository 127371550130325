import React from 'react'
import tw from 'twin.macro'

const Graph = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.6001 19.7999C3.6001 19.3225 3.78974 18.8647 4.12731 18.5271C4.46487 18.1895 4.92271 17.9999 5.4001 17.9999H9.0001C9.47749 17.9999 9.93532 18.1895 10.2729 18.5271C10.6105 18.8647 10.8001 19.3225 10.8001 19.7999V28.7999C10.8001 29.2773 10.6105 29.7351 10.2729 30.0727C9.93532 30.4103 9.47749 30.5999 9.0001 30.5999H5.4001C4.92271 30.5999 4.46487 30.4103 4.12731 30.0727C3.78974 29.7351 3.6001 29.2773 3.6001 28.7999V19.7999ZM14.4001 12.5999C14.4001 12.1225 14.5897 11.6647 14.9273 11.3271C15.2649 10.9895 15.7227 10.7999 16.2001 10.7999H19.8001C20.2775 10.7999 20.7353 10.9895 21.0729 11.3271C21.4105 11.6647 21.6001 12.1225 21.6001 12.5999V28.7999C21.6001 29.2773 21.4105 29.7351 21.0729 30.0727C20.7353 30.4103 20.2775 30.5999 19.8001 30.5999H16.2001C15.7227 30.5999 15.2649 30.4103 14.9273 30.0727C14.5897 29.7351 14.4001 29.2773 14.4001 28.7999V12.5999ZM25.2001 7.1999C25.2001 6.72251 25.3897 6.26468 25.7273 5.92711C26.0649 5.58954 26.5227 5.3999 27.0001 5.3999H30.6001C31.0775 5.3999 31.5353 5.58954 31.8729 5.92711C32.2105 6.26468 32.4001 6.72251 32.4001 7.1999V28.7999C32.4001 29.2773 32.2105 29.7351 31.8729 30.0727C31.5353 30.4103 31.0775 30.5999 30.6001 30.5999H27.0001C26.5227 30.5999 26.0649 30.4103 25.7273 30.0727C25.3897 29.7351 25.2001 29.2773 25.2001 28.7999V7.1999Z"
            fill="currentColor"
        />
    </svg>
)

export default Graph
