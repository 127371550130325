import React from 'react'
import tw from 'twin.macro'

const Export = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.6001 17.0998C3.6001 18.7707 4.26385 20.3731 5.44532 21.5546C6.6268 22.736 8.22923 23.3998 9.9001 23.3998H16.2001V28.0546L13.8727 25.7272C13.5332 25.3993 13.0785 25.2179 12.6066 25.222C12.1346 25.2261 11.6832 25.4154 11.3494 25.7491C11.0157 26.0829 10.8264 26.5343 10.8223 27.0063C10.8182 27.4782 10.9996 27.9329 11.3275 28.2724L16.7275 33.6724C17.065 34.0098 17.5228 34.1994 18.0001 34.1994C18.4774 34.1994 18.9351 34.0098 19.2727 33.6724L24.6727 28.2724C25.0006 27.9329 25.182 27.4782 25.1779 27.0063C25.1738 26.5343 24.9845 26.0829 24.6508 25.7491C24.317 25.4154 23.8656 25.2261 23.3936 25.222C22.9217 25.2179 22.467 25.3993 22.1275 25.7272L19.8001 28.0546V23.3998H24.3001C25.4102 23.3991 26.5084 23.1703 27.5263 22.7275C28.5443 22.2847 29.4604 21.6374 30.2178 20.8257C30.9751 20.014 31.5575 19.0553 31.9288 18.0091C32.3001 16.963 32.4524 15.8516 32.3763 14.7441C32.3001 13.6366 31.9971 12.5566 31.4861 11.5711C30.9751 10.5856 30.2669 9.71562 29.4056 9.01526C28.5443 8.31489 27.5482 7.79904 26.4792 7.49974C25.4102 7.20044 24.2911 7.12408 23.1913 7.2754C22.9787 6.33685 22.5802 5.45041 22.0194 4.66841C21.4586 3.88642 20.7467 3.22472 19.9259 2.72239C19.1051 2.22006 18.192 1.88729 17.2404 1.74371C16.2889 1.60014 15.3182 1.64867 14.3857 1.88645C13.4532 2.12423 12.5778 2.54644 11.8112 3.12814C11.0446 3.70984 10.4023 4.43925 9.92231 5.27329C9.44227 6.10733 9.13421 7.0291 9.01632 7.98417C8.89842 8.93925 8.97309 9.90826 9.2359 10.834C7.68855 10.998 6.25665 11.7289 5.21607 12.8858C4.1755 14.0427 3.59987 15.5438 3.6001 17.0998ZM19.8001 23.3998H16.2001V14.3998C16.2001 13.9224 16.3897 13.4646 16.7273 13.127C17.0649 12.7894 17.5227 12.5998 18.0001 12.5998C18.4775 12.5998 18.9353 12.7894 19.2729 13.127C19.6105 13.4646 19.8001 13.9224 19.8001 14.3998V23.3998Z"
            fill="currentColor"
        />
    </svg>
)

export default Export
