import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../../components/shared/landingPages/layoutComponents'
import Header from '../../components/shared/landingPages/header'
import TopHighlights from '../../components/shared/landingPages/topHighlights'
import HighlightedFunctionality from '../../components/shared/landingPages/highlightedFunctionality'
import Benefits from '../../components/shared/landingPages/benefits'
import FeatureLinks from '../../components/shared/landingPages/featureLinks'
import Graph from '../../images/rapportages/graph.js'
import Validate from '../../images/rapportages/validate.js'
import Export from '../../images/rapportages/export.js'
import Vat from '../../images/rapportages/vat.js'
import Credit from '../../images/icons/credit.js'
import header from '../../images/rapportages/header.png'
import paidOnTime from '../../images/rapportages/paidOnTime.png'
import benefit from '../../images/rapportages/benefits.png'

const topHighlightData = {
    title: `Rapportages in Tellow`,
    points: [
        {
            Icon: Graph,
            title: `Toegankelijk`,
            body: `
            De rapportages zijn altijd en overal toegankelijk vanaf je laptop, tablet of smartphone.
        `,
        },
        {
            Icon: Validate,
            title: `Doelmatig`,
            body: `
            De jaarrekening is zo opgesteld dat deze gebruikt kan worden voor bijvoorbeeld de Belastingdienst, banken en subsidieaanvragen.
        `,
        },
        {
            Icon: Export,
            title: `Exporteren`,
            body: `
            Wanneer het nodig is je gegevens te exporteren kan je ze eenvoudig downloaden in een CSV-bestand of auditfile.
        `,
        },
        {
            Icon: Vat,
            title: `Btw-aangifte`,
            body: `
            Weet direct hoeveel btw je moet betalen of terug kan vragen en verstuur je btw-aangifte direct naar de Belastingdienst.
        `,
        },
    ],
}

const headerData = {
    title: `Rapporten over je boekhouding`,
    text: `Met de automatisch opgestelde rapportages in Tellow weet je altijd hoe jouw onderneming ervoor staat.`,
    headerImage: header,
    textCTA: `Gebruik Tellow gratis`,
}

const highlightedFunctionalityData = {
    title: `Op tijd betaald worden`,
    subtitle: `Tellow geeft je een handig overzicht van betaalde en niet-betaalde facturen. Ook sturen we automatisch betalingsherinneringen aan je klanten. Zo word je sneller betaald en loop je nooit geld mis.`,
    firstStepImage: paidOnTime,
}

const featureData = [
    {
        Icon: Credit,
        title: `Facturen`,
        text: `Stuur onbeperkt facturen in je eigen huisstijl. Gebruik onze gedesignde templates voor een professionele look.`,
        link: `Lees meer over facturen maken`,
        path: `facturen`,
    },
    {
        Icon: Credit,
        title: `Offertes`,
        text: `Stuur professionele offertes en zet deze direct om in facturen. Verhoog de acceptatieratio van je offertes door je eigen huisstijl en logo te gebruiken.`,
        link: `Lees meer over offertes maken`,
        path: `offertes`,
    },
]

const benefitsData = {
    points: [
        {
            title: 'Eenvoudig',
            body: 'Met de rapportage functie van Tellow heb je met één druk op de knop een actueel en leesbaar rapport ter beschikking.',
            links: `Neem een account`,
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Overzicht',
            body: 'Krijg een real-time overzicht van alle belangrijke informatie over je bedrijf. We zetten de informatie automatisch onder de juiste kostenpost. Dit geeft je een goed beeld over de stand van zaken in je boekhouding.',
            links: `Krijg ook real-time inzicht`,
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Inzicht',
            body: 'Met de rapportages van Tellow krijg je inzicht in je administratie. Gebruik de balans, het btw-overzicht en de winst- en verliesrekening voor een diepere inkijk in de financiële situatie van je bedrijf.',
            links: `Probeer nu`,
            url: `https://app.tellow.nl/registreer#/`,
        },
    ],
    benefitImage: benefit,
    altImage: `Benefit Image`,
}

const Rapportages = () => (
    <Layout>
        {/* Page not indexed */}
        <Meta
            keywords={['rapportages', 'zzp', 'tellow']}
            path="/functies/rapportages"
            title="Rapportages | Tellow.nl"
            description="Snel online boekhouden met hét online boekhoudprogramma voor zzp'ers ✅ 100.000+ ondernemers gingen je voor ✅ Registreer gratis!"
        />
        {/* First Component : Header (Main title and image) */}
        <Header data={headerData} bottomImage purpleBackground />

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <Benefits data={benefitsData} />
            </ComponentContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps purple>
            <RelativeContainer>
                <ComponentContainer>
                    <HighlightedFunctionality data={highlightedFunctionalityData} purpleBackground />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <FeatureLinks data={featureData} />
            </ComponentContainer>
        </RelativeContainer>
    </Layout>
)

export default Rapportages
