import React from 'react'
import tw from 'twin.macro'

const Validate = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.1999 3.6001C15.7225 3.6001 15.2647 3.78974 14.9271 4.12731C14.5895 4.46487 14.3999 4.92271 14.3999 5.4001C14.3999 5.87749 14.5895 6.33532 14.9271 6.67289C15.2647 7.01046 15.7225 7.2001 16.1999 7.2001H19.7999C20.2773 7.2001 20.7351 7.01046 21.0727 6.67289C21.4103 6.33532 21.5999 5.87749 21.5999 5.4001C21.5999 4.92271 21.4103 4.46487 21.0727 4.12731C20.7351 3.78974 20.2773 3.6001 19.7999 3.6001H16.1999Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.19995 8.9999C7.19995 8.04512 7.57924 7.12945 8.25437 6.45432C8.9295 5.77919 9.84517 5.3999 10.8 5.3999C10.8 6.83207 11.3689 8.20558 12.3816 9.21828C13.3943 10.231 14.7678 10.7999 16.2 10.7999H19.8C21.2321 10.7999 22.6056 10.231 23.6183 9.21828C24.631 8.20558 25.2 6.83207 25.2 5.3999C26.1547 5.3999 27.0704 5.77919 27.7455 6.45432C28.4207 7.12945 28.8 8.04512 28.8 8.9999V28.7999C28.8 29.7547 28.4207 30.6704 27.7455 31.3455C27.0704 32.0206 26.1547 32.3999 25.2 32.3999H10.8C9.84517 32.3999 8.9295 32.0206 8.25437 31.3455C7.57924 30.6704 7.19995 29.7547 7.19995 28.7999V8.9999ZM24.6726 19.2725C25.0004 18.933 25.1819 18.4783 25.1778 18.0064C25.1737 17.5344 24.9844 17.083 24.6506 16.7492C24.3169 16.4155 23.8654 16.2262 23.3935 16.2221C22.9215 16.218 22.4668 16.3994 22.1274 16.7273L16.2 22.6547L13.8726 20.3273C13.5331 19.9994 13.0784 19.818 12.6064 19.8221C12.1345 19.8262 11.683 20.0155 11.3493 20.3492C11.0155 20.683 10.8262 21.1344 10.8221 21.6064C10.818 22.0783 10.9995 22.533 11.3274 22.8725L14.9274 26.4725C15.2649 26.8099 15.7227 26.9995 16.2 26.9995C16.6772 26.9995 17.135 26.8099 17.4726 26.4725L24.6726 19.2725Z"
            fill="currentColor"
        />
    </svg>
)

export default Validate
